import React, { Component } from "react";
import $ from "jquery";
// import { modifyRecord } from "../utils/Helpers";
// @ukhc/devops-react-library
import ItemsPerPage from "@ukhc/devops-react-library/components/ItemsPerPage";
import Pagination from "@ukhc/devops-react-library/components/Pagination";
import Search from "@ukhc/devops-react-library/components/Search";
import Table from "@ukhc/devops-react-library/components/Table";
import TableToolbar from "@ukhc/devops-react-library/components/TableToolbar";
// App components
import Legend from "./Legend";
import AddProxyModal from "./AddProxyModal";
import RemoveProxyModal from "./RemoveProxyModal";
import CancelSeparationModal from "./CancelSeparationModal";
import EmployeeSkipModal from "./EmployeeSkipModal";
import SignFormModal from "./SignFormModal";
import ApproveFormModal from "./ApproveFormModal";
import ViewFormModal from "./ViewFormModal";
import RecordEditModal from "./RecordEditModal";
import StatusFilterSelect from "./StatusFilterSelect";
import RecordsExportModal from "./RecordsExportModal";
import CompletedCommentModal from "./CompletedCommentModal";

import pdfMake from "pdfmake/build/pdfmake";
import { buildPDF } from "../utils/PDFBuilder";
import { formatFetchData, formatFetchUrl } from "../utils/Helpers";

export default class Records extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addProxyModalShow: false,
      removeProxyModalShow: false,
      approveFormModalShow: false,
      cancelSeparationModalShow: false,
      employeeSkipModalShow: false,
      addCompletedCommentModalShow: false,
      canceledFilter: "",
      currentPage: 1,
      fetchResponseStatusCode: null,
      fetchResponseStatusMessage: null,
      isLoading: true,
      isSearching: false,
      isTableDisabled: true,
      itemsPerPage: 25,
      itemsPerPageOptions: [5, 10, 25, 50, 100, 250, 500],
      recordEditModalShow: false,
      records: [],
      search: null,
      selectedRecord: {},
      selectedRows: [],
      signFormModalShow: false,
      sortDescending: true,
      sortedBy: "separationDate",
      statusFilter: "",
      totalItems: null,
      totalPages: null,
      uidProperty: "id",
      updateRecordModalShow: false,
      viewFormModalShow: false,
      readyForReviewFilter: "",
      employeeSignedFilter: "",
      employeeSignatureNotDesignatedFilter: "",
      supervisorSignedFilter: "",
      supervisorSignatureNotDesignatedFilter: "",
      authorizedIndividualSignedFilter: "",
      authorizedIndividualSignatureNotDesignatedFilter: "",
      hrCompleteFilter: "",
      isExporting: false,
      excelExportData: null,
      recordsExportModalShow: false,
    };

    this.handleAddProxyButtonClick = this.handleAddProxyButtonClick.bind(this);
    this.handleRemoveProxyButtonClick =
      this.handleRemoveProxyButtonClick.bind(this);
    this.handleEditRecordButtonClick =
      this.handleEditRecordButtonClick.bind(this);
    this.handleEmployeeReviewAndSignButtonClick =
      this.handleEmployeeReviewAndSignButtonClick.bind(this);
    this.handleFetch = this.handleFetch.bind(this);
    this.handlePageBack = this.handlePageBack.bind(this);
    this.handlePageForward = this.handlePageForward.bind(this);
    this.handlePayrollReviewAndSignButtonClick =
      this.handlePayrollReviewAndSignButtonClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSetItemsPerPage = this.handleSetItemsPerPage.bind(this);
    this.handleSetPage = this.handleSetPage.bind(this);
    this.handleSetSelectedRows = this.handleSetSelectedRows.bind(this);
    this.handleSetSortedBy = this.handleSetSortedBy.bind(this);
    this.handleSetSortDescending = this.handleSetSortDescending.bind(this);
    this.handleSetStatusFilter = this.handleSetStatusFilter.bind(this);
    this.handleSupervisorReviewAndSignButtonClick =
      this.handleSupervisorReviewAndSignButtonClick.bind(this);
    this.handleApproveSignaturesButtonClick =
      this.handleApproveSignaturesButtonClick.bind(this);
    this.handleViewButtonClick = this.handleViewButtonClick.bind(this);
    this.handleCancelSeparationButtonClick =
      this.handleCancelSeparationButtonClick.bind(this);
    this.handleEmployeeSkipButtonClick =
      this.handleEmployeeSkipButtonClick.bind(this);
    this.handleAddCompletedCommentButtonClick =
      this.handleAddCompletedCommentButtonClick.bind(this);
    this.handleExport = this.handleExport.bind(this);
  }

  // for the data refresh interval
  intervalID;

  componentDidMount() {
    // * FOR DEBUGGING
    // modifyRecord(this.props.user, 61, {
    //   // Canceled?
    //   canceled: false,
    //   // Employee
    //   employeeLinkblue: "rre254",
    //   employeeSigned: false,
    //   employeeSignatureNotDesignated: false,
    //   skipEmployeeSignature: false,
    //   skipEmployeeComment: "",
    //   // Supervisor
    //   supervisorLinkblue: "sama234",
    //   supervisorSigned: false,
    //   supervisorSignatureNotDesignated: false,
    //   // supervisorName: "May, Scott A. - 123456789",
    //   // Authorized Individual
    //   // authorizedIndividualName: "May, Scott A. - 123456789",
    //   authorizedIndividualLinkblue: "sama234",
    //   authorizedIndividualSigned: false,
    //   authorizedIndividualSignatureNotDesignated: true,
    //   // Workforce Management
    //   hrComplete: false,
    // });

    $('[data-toggle="tooltip"]').tooltip();

    this.handleFetch();

    // call handleFetch() every 60 seconds
    this.intervalID = setInterval(this.handleFetch, 60000);
  }

  componentWillUnmount() {
    // Stop handleFetch() from continuing to run even after unmounting component
    clearInterval(this.intervalID);
  }

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  handleAddProxyButtonClick() {
    this.setState(
      {
        addProxyModalShow: true,
      },
      () => {
        $("#addProxyModal").modal("show");
      }
    );
  }

  handleRemoveProxyButtonClick() {
    this.setState(
      {
        removeProxyModalShow: true,
      },
      () => {
        $("#removeProxyModal").modal("show");
      }
    );
  }

  handleCancelSeparationButtonClick() {
    this.setState(
      {
        cancelSeparationModalShow: true,
      },
      () => {
        $("#cancelSeparationModal").modal("show");
      }
    );
  }

  handleEmployeeSkipButtonClick() {
    this.setState(
      {
        employeeSkipModalShow: true,
      },
      () => {
        $("#employeeSkipModal").modal("show");
      }
    );
  }

  handleAddCompletedCommentButtonClick() {
    this.setState(
      {
        addCompletedCommentModalShow: true,
      },
      () => {
        $("#addCompletedCommentModal").modal("show");
      }
    );
  }

  handleEditRecordButtonClick() {
    let selectedRows = this.state.selectedRows;
    let selectedRecord = this.state.records.filter((record) => {
      return record.id === selectedRows[0].uid;
    })[0];
    this.setState(
      {
        recordEditModalShow: true,
        selectedRecord: selectedRecord,
      },
      () => {
        $("#recordEditModal").modal("show");
      }
    );
  }

  handleEmployeeReviewAndSignButtonClick() {
    this.setState(
      {
        signFormModalShow: true,
      },
      () => {
        $("#signFormModal").modal("show");
      }
    );
  }

  async handleExport() {
    if (window.REACT_APP_DEBUG) console.log("Records: handleExport");

    this.setState({
      isExporting: true,
    });

    const url = formatFetchUrl(
      "records",
      null,
      this.state.itemsPerPage,
      this.state.currentPage,
      this.state.sortedBy,
      this.state.sortDescending,
      this.state.search,
      this.state.readyForReviewFilter,
      this.state.employeeSignedFilter,
      this.state.employeeSignatureNotDesignatedFilter,
      this.state.supervisorSignedFilter,
      this.state.supervisorSignatureNotDesignatedFilter,
      this.state.authorizedIndividualSignedFilter,
      this.state.authorizedIndividualSignatureNotDesignatedFilter,
      this.state.hrCompleteFilter,
      this.state.canceledFilter,
      true // exportAll
    );

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.user.apiToken.accessToken}`,
        },
      });

      if (!response.ok) {
        throw response.status;
      }

      const data = await response.json();

      const formattedData = formatFetchData(data, this.props.user);

      const dataToExport = formattedData.map(
        ({
          authorizedIndividualCanEdit,
          authorizedIndividualCanSign,
          authorizedIndividualEmailAddress,
          authorizedIndividualEmailSentOn,
          authorizedIndividualLinkBlueId,
          authorizedIndividualName,
          authorizedIndividualNameFormatted,
          authorizedIndividualSignatureDate,
          authorizedIndividualSignatureDateFormatted,
          authorizedIndividualSignatureNotDesignated,
          authorizedIndividualSigned,
          authorizedIndividualSignedFormatted,
          canceled,
          canceledComments,
          canceledFormatted,
          commentsToShow,
          createdBy,
          createdOn,
          dateNoticeGiven,
          dateNoticeGivenFormatted,
          division,
          emails,
          employeeCanSign,
          employeeEmailAddress,
          employeeEmailSentOn,
          employeeFirstName,
          employeeLastName,
          employeeMiddleInitial,
          employeeSignatureDate,
          employeeSignatureDateFormatted,
          employeeSignatureNotDesignated,
          employeeSigned,
          employeeSignedFormatted,
          firstDateWorked,
          firstDateWorkedFormatted,
          firstDayPayRate,
          firstDayPayRatePer,
          firstDayStatusPTFT,
          forwardingAddress,
          hrComplete,
          hrCompleteBy,
          hrCompleteByName,
          hrCompleteOn,
          id,
          isEmployeeEligleForRehire,
          lastDateWorked,
          lastDateWorkedFormatted,
          lastDayPayRatePer,
          lastDayStatusPTFT,
          lastDayStatusTempRegular,
          // lastDayTitle,
          lastUpdatedBy,
          lastUpdatedOn,
          missingSignatures,
          payrollAmount,
          payrollOfficialName,
          payrollOfficialSignatureDate,
          payrollOfficialSigned,
          payrollOfficialTitle,
          positionCode,
          // primaryReasonValue,
          proxyEmailAddress,
          proxyEmailSentOn,
          readyForReview,
          recordType,
          requestorLinkBlueId,
          requestorName,
          // secondaryReasonValue,
          separationDate,
          separationEmployeeContact,
          separationEmployeeContactDomainName,
          separationFirstDayStatusTempRegular,
          separationFirstDayTitle,
          separationManagerContact,
          separationManagerContactDomainName,
          separationNoticeGivenVia,
          // separationReasonExplanation,
          separationReasons,
          separationReasonsFormatted,
          separationSectionDEmployeeComments,
          separationSectionFHRComments,
          signingPhase,
          skipEmployeeComment,
          skipEmployeeSignature,
          supervisorCanSign,
          supervisorContact,
          supervisorContactDomainName,
          supervisorEmailAddress,
          supervisorEmailSentOn,
          supervisorName,
          supervisorProxySignature,
          supervisorSignatureDate,
          supervisorSignatureDateFormatted,
          supervisorSignatureNotDesignated,
          supervisorSigned,
          supervisorSignedFormatted,
          terminalHrsToBePaid,
          ...columnsToKeep
        }) => ({
          ...columnsToKeep,
        })
      );

      this.setState(
        {
          excelExportData: dataToExport,
          isExporting: false,
        },
        () => {
          this.setState({ recordsExportModalShow: true }, () => {
            $("#recordsExportModal").modal("show");
          });
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  handleFetch() {
    if (window.REACT_APP_DEBUG) console.log("Records: handlefetch");

    const url = formatFetchUrl(
      "records",
      null,
      this.state.itemsPerPage,
      this.state.currentPage,
      this.state.sortedBy,
      this.state.sortDescending,
      this.state.search,
      this.state.readyForReviewFilter,
      this.state.employeeSignedFilter,
      this.state.employeeSignatureNotDesignatedFilter,
      this.state.supervisorSignedFilter,
      this.state.supervisorSignatureNotDesignatedFilter,
      this.state.authorizedIndividualSignedFilter,
      this.state.authorizedIndividualSignatureNotDesignatedFilter,
      this.state.hrCompleteFilter,
      this.state.canceledFilter
    );

    if (window.REACT_APP_DEBUG)
      console.log("Records handleFetch records: " + url);

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.apiToken.accessToken}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          // get pagination from header
          let pagination = JSON.parse(response.headers.get("x-pagination"));
          this.setState({
            currentPage: pagination.currentPage,
            fetchResponseStatusCode: response.status,
            totalPages: pagination.totalPages,
            totalItems: pagination.totalItems,
          });

          return response.json();
        }
        this.setState({ fetchResponseStatusCode: response.status });
        throw response.status;
      })
      .then((data) => {
        const formattedData = formatFetchData(data, this.props.user);

        if (window.REACT_APP_DEBUG) console.log(formattedData);

        this.setState({
          isLoading: false,
          isSearching: false,
          isTableDisabled: false,
          records: formattedData || [],
        });

        // Update the selected record
        if (this.state.selectedRows.length > 0 && data.length > 0) {
          let selectedRow = this.state.selectedRows[0];
          let selectedRecordValue = {};
          selectedRecordValue = data.filter((record) => {
            return record.id === selectedRow.uid;
          })[0];
          this.setState({
            selectedRecord: selectedRecordValue,
          });
        }
      })
      .catch((error) => {
        if (window.REACT_APP_DEBUG) {
          console.log("Records error: " + error);
          console.dir(error);
        }

        if (error === 404) {
          this.setState({
            fetchResponseStatusCode: 404,
            fetchResponseStatusMessage: "No records found.",
          });
        }

        this.setState({
          isLoading: false,
          isSearching: false,
          isTableDisabled: false,
          records: [],
          totalPages: 0,
          totalItems: 0,
        });
      });
  }

  handlePayrollReviewAndSignButtonClick() {
    this.setState(
      {
        signFormModalShow: true,
      },
      () => {
        $("#signFormModal").modal("show");
      }
    );
  }

  handleSearch(search) {
    this.setState(
      {
        isSearching: true,
        search: search,
        currentPage: 1,
      },
      () => {
        this.handleFetch();
      }
    );
  }

  handleSetItemsPerPage(limit) {
    this.setState(
      {
        itemsPerPage: limit,
        currentPage: 1,
      },
      () => {
        this.handleFetch();
      }
    );
  }

  handleSetPage(page) {
    this.setState(
      {
        currentPage: page,
        selectedRecord: {},
        selectedRows: [],
      },
      () => {
        this.handleFetch();
      }
    );
  }

  handleSetSelectedRows(rows) {
    if (rows) {
      var selectedRecord = {};
      if (rows.length > 0) {
        selectedRecord = this.state.records.filter((record) => {
          return record.id === rows[0].uid;
        })[0];
      }
      if (window.REACT_APP_DEBUG) {
        console.log("handleSetSelectedRows:");
        console.log(selectedRecord);
      }

      this.setState({
        selectedRows: rows,
        selectedRecord: selectedRecord,
      });
    } else {
      this.setState({
        selectedRows: [],
        selectedRecord: {},
      });
    }
  }

  handleSetSortedBy(field) {
    if (window.REACT_APP_DEBUG) console.log("handleSetSortedBy: " + field);
    this.setState(
      {
        currentPage: 1,
        isTableDisabled: true,
        sortedBy: field,
      },
      () => {
        this.handleFetch();
      }
    );
  }

  handleSetStatusFilter(value) {
    switch (value) {
      case "Ready for review":
        this.setState(
          {
            authorizedIndividualSignatureNotDesignatedFilter: "",
            authorizedIndividualSignedFilter: "",
            currentPage: 1,
            employeeSignatureNotDesignatedFilter: "",
            employeeSignedFilter: "",
            hrCompleteFilter: "",
            readyForReviewFilter: "true",
            selectedRecord: {},
            selectedRows: [],
            supervisorSignatureNotDesignatedFilter: "",
            supervisorSignedFilter: "",
            canceledFilter: "false",
          },
          () => {
            this.handleFetch();
          }
        );
        break;
      case "Incomplete":
        this.setState(
          {
            authorizedIndividualSignatureNotDesignatedFilter: "",
            authorizedIndividualSignedFilter: "",
            currentPage: 1,
            employeeSignatureNotDesignatedFilter: "",
            employeeSignedFilter: "",
            hrCompleteFilter: "false",
            readyForReviewFilter: "",
            selectedRecord: {},
            selectedRows: [],
            supervisorSignatureNotDesignatedFilter: "",
            supervisorSignedFilter: "",
            canceledFilter: "false",
          },
          () => {
            this.handleFetch();
          }
        );
        break;
      case "Complete":
        this.setState(
          {
            authorizedIndividualSignatureNotDesignatedFilter: "",
            authorizedIndividualSignedFilter: "",
            currentPage: 1,
            employeeSignatureNotDesignatedFilter: "",
            employeeSignedFilter: "",
            hrCompleteFilter: "true",
            readyForReviewFilter: "",
            selectedRecord: {},
            selectedRows: [],
            supervisorSignatureNotDesignatedFilter: "",
            supervisorSignedFilter: "",
            canceledFilter: "false",
          },
          () => {
            this.handleFetch();
          }
        );
        break;
      case "Needs signature from individual authorized to remove employee from payroll":
        this.setState(
          {
            authorizedIndividualSignatureNotDesignatedFilter: "false",
            authorizedIndividualSignedFilter: "false",
            currentPage: 1,
            employeeSignatureNotDesignatedFilter: "",
            employeeSignedFilter: "",
            hrCompleteFilter: "",
            readyForReviewFilter: "",
            selectedRecord: {},
            selectedRows: [],
            supervisorSignatureNotDesignatedFilter: "",
            supervisorSignedFilter: "",
            canceledFilter: "false",
          },
          () => {
            this.handleFetch();
          }
        );
        break;
      case "Needs signature from supervisor":
        this.setState(
          {
            authorizedIndividualSignatureNotDesignatedFilter: "",
            authorizedIndividualSignedFilter: "",
            currentPage: 1,
            employeeSignatureNotDesignatedFilter: "",
            employeeSignedFilter: "",
            hrCompleteFilter: "",
            readyForReviewFilter: "",
            selectedRecord: {},
            selectedRows: [],
            supervisorSignatureNotDesignatedFilter: "false",
            supervisorSignedFilter: "false",
            canceledFilter: "false",
          },
          () => {
            this.handleFetch();
          }
        );
        break;
      case "Needs signature from employee":
        this.setState(
          {
            authorizedIndividualSignatureNotDesignatedFilter: "",
            authorizedIndividualSignedFilter: "",
            currentPage: 1,
            employeeSignatureNotDesignatedFilter: "false",
            employeeSignedFilter: "false",
            hrCompleteFilter: "",
            readyForReviewFilter: "",
            selectedRecord: {},
            selectedRows: [],
            supervisorSignatureNotDesignatedFilter: "",
            supervisorSignedFilter: "",
            canceledFilter: "false",
          },
          () => {
            this.handleFetch();
          }
        );
        break;
      case "Canceled":
        this.setState(
          {
            authorizedIndividualSignatureNotDesignatedFilter: "",
            authorizedIndividualSignedFilter: "",
            currentPage: 1,
            employeeSignatureNotDesignatedFilter: "",
            employeeSignedFilter: "",
            hrCompleteFilter: "",
            readyForReviewFilter: "",
            selectedRecord: {},
            selectedRows: [],
            supervisorSignatureNotDesignatedFilter: "",
            supervisorSignedFilter: "",
            canceledFilter: "true",
          },
          () => {
            this.handleFetch();
          }
        );
        break;
      default:
        this.setState(
          {
            authorizedIndividualSignatureNotDesignatedFilter: "",
            authorizedIndividualSignedFilter: "",
            currentPage: 1,
            employeeSignatureNotDesignatedFilter: "",
            employeeSignedFilter: "",
            hrCompleteFilter: "",
            readyForReviewFilter: "",
            selectedRecord: {},
            selectedRows: [],
            supervisorSignatureNotDesignatedFilter: "",
            supervisorSignedFilter: "",
            canceledFilter: "",
          },
          () => {
            this.handleFetch();
          }
        );
    }
  }

  handleGeneratePDFButtonClick(record) {
    const docDefinition = buildPDF(record);
    pdfMake.createPdf(docDefinition).open();
  }

  handleSupervisorReviewAndSignButtonClick() {
    this.setState(
      {
        signFormModalShow: true,
      },
      () => {
        $("#signFormModal").modal("show");
      }
    );
  }

  handleApproveSignaturesButtonClick() {
    this.setState(
      {
        approveFormModalShow: true,
      },
      () => {
        $("#approveFormModal").modal("show");
      }
    );
  }

  handleViewButtonClick() {
    this.setState(
      {
        viewFormModalShow: true,
      },
      () => {
        $("#viewFormModal").modal("show");
      }
    );
  }

  handleSetSortDescending(boolValue) {
    this.setState(
      {
        currentPage: 1,
        isTableDisabled: true,
        sortDescending: boolValue,
      },
      () => {
        this.handleFetch();
      }
    );
  }

  handlePageForward() {
    var newPage;
    if (this.state.currentPage >= this.state.totalPages) {
      newPage = this.state.totalPages;
    } else {
      newPage = this.state.currentPage + 1;
    }
    this.setState(
      {
        currentPage: newPage,
        selectedRecord: {},
        selectedRows: [],
      },
      () => {
        this.handleFetch();
      }
    );
  }

  handlePageBack() {
    var newPage;
    if (this.state.currentPage < 2) {
      newPage = 1;
    } else {
      newPage = this.state.currentPage - 1;
    }
    this.setState(
      {
        currentPage: newPage,
        selectedRecord: {},
        selectedRows: [],
      },
      () => {
        this.handleFetch();
      }
    );
  }

  render() {
    let recordEditModalClose = () => {
      this.setState(
        {
          recordEditModalShow: false,
        },
        () => {
          this.handleFetch();
        }
      );
    };

    let signFormModalClose = () => {
      this.setState(
        {
          signFormModalShow: false,
          selectedRows: [],
          selectedRecord: {},
        },
        () => {
          this.handleFetch();
        }
      );
    };

    let approveFormModalClose = () => {
      this.setState(
        {
          approveFormModalShow: false,
          selectedRows: [],
          selectedRecord: {},
        },
        () => {
          this.handleFetch();
        }
      );
    };

    let viewFormModalClose = () => {
      this.setState(
        {
          viewFormModalShow: false,
        },
        () => {
          this.handleFetch();
        }
      );
    };

    let addProxyModalClose = () => {
      this.setState(
        {
          addProxyModalShow: false,
        },
        () => {
          this.handleFetch();
        }
      );
    };

    let removeProxyModalClose = () => {
      this.setState(
        {
          removeProxyModalShow: false,
        },
        () => {
          this.handleFetch();
        }
      );
    };

    let cancelSeparationModalClose = () => {
      this.setState(
        {
          cancelSeparationModalShow: false,
        },
        () => {
          this.handleFetch();
        }
      );
    };

    let employeeSkipModalClose = () => {
      this.setState(
        {
          employeeSkipModalShow: false,
        },
        () => {
          this.handleFetch();
        }
      );
    };

    let addCompletedCommentModalClose = () => {
      this.setState(
        {
          addCompletedCommentModalShow: false,
        },
        () => {
          this.handleFetch();
        }
      );
    };

    let recordsExportModalClose = () =>
      this.setState({ recordsExportModalShow: false });

    // get the selected records into an array
    var selectedRecords = [];
    for (const row of this.state.selectedRows) {
      let rowToAdd = this.state.records.filter(
        (record) => record[this.state.uidProperty] === row.uid
      )[0];
      if (rowToAdd) {
        selectedRecords.push(rowToAdd);
      }
    }

    var resultsCountString = "";
    if (this.state.totalItems > 0) {
      resultsCountString =
        this.state.currentPage * this.state.itemsPerPage -
        (this.state.itemsPerPage - 1) +
        "-" +
        (this.state.currentPage * this.state.itemsPerPage -
          this.state.itemsPerPage +
          this.state.records.length) +
        " of " +
        this.state.totalItems;
    } else {
      resultsCountString = "0 items";
    }

    const recordSelected = selectedRecords.length === 1;

    // User roles
    const isHrManager = this.props.user.isHrManager;
    const isAdministrator = this.props.user.isAdministrator;
    const isSupervisorOrProxy =
      this.props.user.isSupervisor || this.props.user.isProxy;
    const isAuthorizedIndividual =
      recordSelected &&
      selectedRecords[0].authorizedIndividualLinkBlueId ===
        this.props.user.linkblue;
    const isSupervisorOfSelectedUser =
      recordSelected &&
      ((selectedRecords[0].supervisorLinkBlueId &&
        selectedRecords[0].supervisorLinkBlueId.toLowerCase() ===
          this.props.user.linkblue.toLowerCase()) ||
        (selectedRecords[0].proxyLinkBlueId !== null &&
          selectedRecords[0].proxyLinkBlueId.toLowerCase() ===
            this.props.user.linkblue.toLowerCase()));
    const isAuthorizedIndividualOfSelectedUser =
      recordSelected &&
      selectedRecords[0].authorizedIndividualLinkBlueId &&
      this.props.user.linkblue &&
      selectedRecords[0].authorizedIndividualLinkBlueId.toLowerCase() ===
        this.props.user.linkblue.toLowerCase();
    const isEmployee =
      recordSelected &&
      this.props.user.linkblue &&
      selectedRecords[0].employeeLinkBlueId &&
      this.props.user.linkblue.toLowerCase() ===
        selectedRecords[0].employeeLinkBlueId.toLowerCase();
    const proxyExists =
      recordSelected &&
      selectedRecords[0].proxyName &&
      selectedRecords[0].proxyLinkBlueId;

    // Signature phases
    const isHrSigningPhase =
      recordSelected && selectedRecords[0].signingPhase === "hr";
    const isSupervisorSigningPhase =
      recordSelected && selectedRecords[0].supervisorCanSign;
    const isAuthorizedIndividualSigningPhase =
      recordSelected && selectedRecords[0].authorizedIndividualCanSign;
    const isEmployeeSigningPhase =
      recordSelected && selectedRecords[0].employeeCanSign;
    const isComplete = recordSelected && selectedRecords[0].hrComplete;
    const isCanceled = recordSelected && selectedRecords[0].canceled;
    const isReadyForReview =
      recordSelected && selectedRecords[0].readyForReview;

    // Signatures/Not Designated
    const skipEmployeeSignature =
      recordSelected && selectedRecords[0].skipEmployeeSignature;
    const employeeSigned = recordSelected && selectedRecords[0].employeeSigned;
    const employeeSignatureNotDesignated =
      recordSelected && selectedRecords[0].employeeSignatureNotDesignated;

    return (
      <div className="container-fluid">
        <div className="row no-gutters">
          <div className="col mt-4 col-md-3 mb-2">
            <h4>Employee Records</h4>
          </div>
          <div className="col text-right mt-3">
            <Legend />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body pb-1">
                <div className="row mb-3">
                  <div className="col col-12 col-sm-6">
                    <StatusFilterSelect
                      disabled={
                        this.state.isTableDisabled ||
                        (this.state.fetchResponseStatusCode > 399 &&
                          this.state.fetchResponseStatusCode !== 404)
                      }
                      handleSetFilter={this.handleSetStatusFilter}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col col-12 col-md-4">
                    <Search
                      isSearching={this.state.isSearching}
                      onChange={this.handleSearch}
                    />
                  </div>
                  <div className="col col-12 col-md-8 text-right pr-2">
                    <div className="d-flex align-items-center justify-content-end">
                      <TableToolbar
                        deleteMultiple={false}
                        disabled={
                          this.state.isTableDisabled ||
                          (this.state.fetchResponseStatusCode > 399 &&
                            this.state.fetchResponseStatusCode !== 404)
                        }
                        editMultiple={false}
                        handleEdit={this.handleEditRecordButtonClick}
                        selectedRecords={selectedRecords}
                        showAddButton={false}
                        showDeleteButton={false}
                        showEditButton={false}
                        showExportButton={false}
                      />
                      {recordSelected &&
                        isHrManager &&
                        isHrSigningPhase &&
                        !isComplete &&
                        !isCanceled && (
                          <button
                            type="button"
                            id="approveSignaturesButton"
                            className="btn btn-link btn-sm"
                            disabled={
                              this.props.disabled ||
                              this.state.isTableDisabled ||
                              (this.state.fetchResponseStatusCode > 399 &&
                                this.state.fetchResponseStatusCode !== 404)
                            }
                            onClick={this.handleApproveSignaturesButtonClick}
                            style={{
                              fontSize: 13,
                              color: "#0067d5",
                            }}
                          >
                            <i className="bi bi-check2-square"></i> Approve
                          </button>
                        )}

                      {recordSelected &&
                        (isHrManager ||
                          isAdministrator ||
                          isSupervisorOrProxy ||
                          isAuthorizedIndividual) &&
                        // isHrSigningPhase &&
                        // isComplete &&
                        !isCanceled && (
                          <button
                            type="button"
                            id="generatePDFButton"
                            className="btn btn-link btn-sm"
                            disabled={
                              this.props.disabled ||
                              this.state.isTableDisabled ||
                              (this.state.fetchResponseStatusCode > 399 &&
                                this.state.fetchResponseStatusCode !== 404)
                            }
                            onClick={() => {
                              this.handleGeneratePDFButtonClick(
                                selectedRecords[0]
                              );
                            }}
                            style={{
                              fontSize: 13,
                              color: "#0067d5",
                            }}
                          >
                            <i className="bi bi-cloud-download"></i> Download
                            PDF
                          </button>
                        )}
                      {recordSelected &&
                        isSupervisorSigningPhase &&
                        isSupervisorOrProxy &&
                        !isComplete &&
                        !isCanceled &&
                        isSupervisorOfSelectedUser && (
                          <button
                            type="button"
                            id="reviewAndSignButton"
                            className="btn btn-link btn-sm"
                            disabled={
                              this.props.disabled ||
                              this.state.isTableDisabled ||
                              (this.state.fetchResponseStatusCode > 399 &&
                                this.state.fetchResponseStatusCode !== 404)
                            }
                            onClick={
                              this.handleSupervisorReviewAndSignButtonClick
                            }
                            style={{
                              fontSize: 13,
                              color: "#0067d5",
                            }}
                          >
                            <i className="bi bi-pen"></i> Review/Sign
                          </button>
                        )}
                      {recordSelected &&
                        isAuthorizedIndividualSigningPhase &&
                        isAuthorizedIndividual &&
                        isAuthorizedIndividualOfSelectedUser &&
                        !isCanceled && (
                          <button
                            type="button"
                            id="reviewAndSignButton"
                            className="btn btn-link btn-sm"
                            disabled={
                              this.props.disabled ||
                              this.state.isTableDisabled ||
                              (this.state.fetchResponseStatusCode > 399 &&
                                this.state.fetchResponseStatusCode !== 404)
                            }
                            onClick={this.handlePayrollReviewAndSignButtonClick}
                            style={{
                              fontSize: 13,
                              color: "#0067d5",
                            }}
                          >
                            <i className="bi bi-pen"></i> Review/Sign
                          </button>
                        )}
                      {recordSelected &&
                        isEmployeeSigningPhase &&
                        isEmployee &&
                        !isCanceled && (
                          <button
                            type="button"
                            id="reviewAndSignButton"
                            className="btn btn-link btn-sm"
                            disabled={
                              this.props.disabled ||
                              this.state.isTableDisabled ||
                              (this.state.fetchResponseStatusCode > 399 &&
                                this.state.fetchResponseStatusCode !== 404)
                            }
                            onClick={
                              this.handleEmployeeReviewAndSignButtonClick
                            }
                            style={{
                              fontSize: 13,
                              color: "#0067d5",
                            }}
                          >
                            <i className="bi bi-pen"></i> Review/Sign
                          </button>
                        )}
                      {recordSelected &&
                        (isHrManager ||
                          isComplete ||
                          (isSupervisorSigningPhase && !isSupervisorOrProxy) ||
                          (isAuthorizedIndividualSigningPhase &&
                            isAuthorizedIndividual) ||
                          (isEmployeeSigningPhase && isEmployee) ||
                          ((isSupervisorSigningPhase ||
                            isAuthorizedIndividualSigningPhase) &&
                            isEmployee) ||
                          (isReadyForReview && !isHrManager)) &&
                        !isCanceled && (
                          <button
                            type="button"
                            id="reviewAndSignButton"
                            className="btn btn-link btn-sm"
                            disabled={
                              this.props.disabled ||
                              this.state.isTableDisabled ||
                              (this.state.fetchResponseStatusCode > 399 &&
                                this.state.fetchResponseStatusCode !== 404)
                            }
                            onClick={this.handleViewButtonClick}
                            style={{
                              fontSize: 13,
                              color: "#0067d5",
                            }}
                          >
                            <i className="bi bi-eye"></i> View
                          </button>
                        )}
                      {recordSelected &&
                        (isAdministrator || isHrManager) &&
                        !isComplete &&
                        !isCanceled && (
                          <button
                            type="button"
                            id="addProxyButton"
                            className="btn btn-link btn-sm"
                            disabled={
                              this.props.disabled ||
                              this.state.isTableDisabled ||
                              (this.state.fetchResponseStatusCode > 399 &&
                                this.state.fetchResponseStatusCode !== 404)
                            }
                            onClick={this.handleAddProxyButtonClick}
                            style={{
                              fontSize: 13,
                              color: "#0067d5",
                            }}
                          >
                            <i className="bi bi-person-plus"></i> Assign Proxy
                          </button>
                        )}
                      {recordSelected &&
                        (isAdministrator || isHrManager) &&
                        proxyExists &&
                        !isComplete &&
                        !isCanceled && (
                          <button
                            type="button"
                            id="removeProxyButton"
                            className="btn btn-link btn-sm"
                            disabled={
                              this.props.disabled ||
                              this.state.isTableDisabled ||
                              (this.state.fetchResponseStatusCode > 399 &&
                                this.state.fetchResponseStatusCode !== 404)
                            }
                            onClick={this.handleRemoveProxyButtonClick}
                            style={{
                              fontSize: 13,
                              color: "#0067d5",
                            }}
                          >
                            <i className="bi bi-person-x"></i> Remove Proxy
                          </button>
                        )}
                      {recordSelected &&
                        (isAdministrator || isHrManager) &&
                        !isComplete &&
                        !isCanceled && (
                          <button
                            type="button"
                            id="cancelSeparationButton"
                            className="btn btn-link btn-sm"
                            disabled={
                              this.props.disabled ||
                              this.state.isTableDisabled ||
                              (this.state.fetchResponseStatusCode > 399 &&
                                this.state.fetchResponseStatusCode !== 404)
                            }
                            onClick={this.handleCancelSeparationButtonClick}
                            style={{
                              fontSize: 13,
                              color: "#0067d5",
                            }}
                          >
                            <i className="bi bi-x-circle"></i> Cancel
                          </button>
                        )}
                      {recordSelected &&
                        (isAdministrator || isHrManager) &&
                        !skipEmployeeSignature &&
                        !employeeSigned &&
                        !isCanceled &&
                        !isComplete &&
                        !employeeSignatureNotDesignated && (
                          <button
                            type="button"
                            id="employeeSkipButton"
                            className="btn btn-link btn-sm"
                            disabled={
                              this.props.disabled ||
                              this.state.isTableDisabled ||
                              (this.state.fetchResponseStatusCode > 399 &&
                                this.state.fetchResponseStatusCode !== 404)
                            }
                            onClick={this.handleEmployeeSkipButtonClick}
                            style={{
                              fontSize: 13,
                              color: "#0067d5",
                            }}
                          >
                            <i className="bi bi-arrow-return-right"></i> Skip
                            Employee Signature
                          </button>
                        )}
                      {(isAdministrator || isHrManager) && isComplete && (
                        <button
                          type="button"
                          id="addCompletedCommentButton"
                          className="btn btn-link btn-sm"
                          disabled={
                            this.props.disabled ||
                            this.state.isTableDisabled ||
                            (this.state.fetchResponseStatusCode > 399 &&
                              this.state.fetchResponseStatusCode !== 404)
                          }
                          onClick={this.handleAddCompletedCommentButtonClick}
                          style={{
                            fontSize: 13,
                            color: "#0067d5",
                          }}
                        >
                          <i className="bi bi-chat-dots"></i> Add comments
                        </button>
                      )}
                      {selectedRecords.length === 0 &&
                        (isAdministrator || isHrManager) && (
                          <button
                            type="button"
                            id="exportRecordsButton"
                            className="btn btn-link btn-sm"
                            disabled={
                              this.props.disabled ||
                              this.state.isTableDisabled ||
                              (this.state.fetchResponseStatusCode > 399 &&
                                this.state.fetchResponseStatusCode !== 404)
                            }
                            onClick={this.handleExport}
                            style={{
                              fontSize: 13,
                              color: "#0067d5",
                            }}
                          >
                            <i className="bi bi-download"></i> Export
                          </button>
                        )}
                    </div>
                  </div>
                </div>
                <div className="row no-gutters pt-3">
                  <div
                    className="col overflow-auto"
                    style={{ height: `calc(100vh - 373px)` }}
                  >
                    <Table
                      checkboxSelectOnly={false}
                      colorRowWarningProperty="displayAsMissingSignaturesInTable"
                      colorRowWarningValue={true}
                      colorRowSuccessProperty="readyForReview"
                      colorRowSuccessValue={true}
                      colorRowSecondaryProperty="canceled"
                      colorRowSecondaryValue={true}
                      columns={tableColumns}
                      disabled={this.state.isTableDisabled}
                      handleSetSelectedRows={this.handleSetSelectedRows}
                      handleSetSortedBy={this.handleSetSortedBy}
                      handleSetSortDescending={this.handleSetSortDescending}
                      isBordered={false}
                      isLoading={this.state.isLoading}
                      isMultiSelect={false}
                      isSmall={true}
                      records={this.state.records}
                      responseStatusCode={this.state.fetchResponseStatusCode}
                      responseStatusMessage={
                        this.state.fetchResponseStatusMessage
                      }
                      selectedRows={this.state.selectedRows}
                      sortDescending={this.state.sortDescending}
                      sortedBy={this.state.sortedBy}
                      uidProperty={this.state.uidProperty}
                      useHoverableRows={true}
                      useStripedRows={false}
                    />
                  </div>
                </div>
                <hr className="my-0 py-0" />
                <div className="row no-gutters">
                  <div className="col mt-1">
                    <ItemsPerPage
                      disabled={
                        this.state.isTableDisabled ||
                        (this.state.fetchResponseStatusCode > 399 &&
                          this.state.fetchResponseStatusCode !== 404)
                      }
                      onChange={this.handleSetItemsPerPage}
                      options={this.state.itemsPerPageOptions}
                      value={this.state.itemsPerPage}
                    />
                  </div>
                  <div className="col align-middle">
                    <div>
                      <center>
                        <div className="mt-2 text-muted">
                          {resultsCountString}
                        </div>
                      </center>
                    </div>
                  </div>
                  <div className="col d-flex justify-content-end py-1">
                    <Pagination
                      currentPage={this.state.currentPage}
                      disabled={
                        this.state.isTableDisabled ||
                        (this.state.fetchResponseStatusCode > 399 &&
                          this.state.fetchResponseStatusCode !== 404)
                      }
                      onChange={this.handleSetPage}
                      totalPages={Math.ceil(
                        this.state.totalItems / this.state.itemsPerPage
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.recordEditModalShow &&
          this.state.selectedRecord !== null && (
            <RecordEditModal
              onHide={recordEditModalClose}
              record={this.state.selectedRecord}
              show={this.state.recordEditModalShow}
              user={this.props.user}
            />
          )}
        {this.state.signFormModalShow && this.state.selectedRecord !== null && (
          <SignFormModal
            onHide={signFormModalClose}
            record={this.state.selectedRecord}
            show={this.state.signFormModalShow}
            user={this.props.user}
          />
        )}
        {this.state.approveFormModalShow &&
          this.state.selectedRecord !== null && (
            <ApproveFormModal
              onHide={approveFormModalClose}
              record={this.state.selectedRecord}
              show={this.state.approveFormModalShow}
              user={this.props.user}
            />
          )}
        {this.state.viewFormModalShow && this.state.selectedRecord !== null && (
          <ViewFormModal
            onHide={viewFormModalClose}
            record={this.state.selectedRecord}
            show={this.state.viewFormModalShow}
            user={this.props.user}
          />
        )}
        {this.state.addProxyModalShow && (
          <AddProxyModal
            onHide={addProxyModalClose}
            record={this.state.selectedRecord}
            show={this.state.addProxyModalShow}
            user={this.props.user}
          />
        )}
        {this.state.removeProxyModalShow && (
          <RemoveProxyModal
            onHide={removeProxyModalClose}
            record={this.state.selectedRecord}
            show={this.state.removeProxyModalShow}
            user={this.props.user}
          />
        )}
        {this.state.cancelSeparationModalShow && (
          <CancelSeparationModal
            onHide={cancelSeparationModalClose}
            record={this.state.selectedRecord}
            show={this.state.cancelSeparationModalShow}
            user={this.props.user}
          />
        )}
        {this.state.employeeSkipModalShow && (
          <EmployeeSkipModal
            onHide={employeeSkipModalClose}
            record={this.state.selectedRecord}
            show={this.state.employeeSkipModalShow}
            user={this.props.user}
          />
        )}
        {this.state.addCompletedCommentModalShow && (
          <CompletedCommentModal
            onHide={addCompletedCommentModalClose}
            record={this.state.selectedRecord}
            show={this.state.addCompletedCommentModalShow}
            user={this.props.user}
          />
        )}
        {this.state.recordsExportModalShow && (
          <RecordsExportModal
            onHide={recordsExportModalClose}
            show={this.state.recordsExportModalShow}
            records={this.state.excelExportData}
          />
        )}
      </div>
    );
  }
}

let tableColumns = [
  {
    name: "PR #",
    property: "prNumber",
    sortable: true,
    nowrap: true,
  },
  {
    name: "Employee",
    property: "employeeNameFormatted",
    sortable: true,
    sortableProperty: "employeeLastName",
    nowrap: true,
  },
  {
    name: "Employee ID",
    property: "employeePersonId",
    sortable: true,
    nowrap: true,
  },
  {
    name: "Employee linkblue",
    property: "employeeLinkBlueId",
    sortable: true,
    nowrap: true,
  },
  {
    name: "Separation Date",
    property: "separationDateFormatted",
    sortableProperty: "separationDate",
    sortable: true,
    align: "center",
    nowrap: true,
  },
  {
    name: "Employee Sub-Group",
    property: "employeeSubGroup",
    sortable: true,
    nowrap: true,
  },
  {
    name: "Department Name",
    property: "deptName",
    sortable: true,
    nowrap: true,
  },
  {
    name: "Department #",
    property: "orgUnit",
    sortable: true,
    nowrap: true,
    align: "center",
  },
  {
    name: "Supervisor",
    property: "supervisorNameFormatted",
    sortableProperty: "supervisorName",
    sortable: true,
    nowrap: true,
  },
  {
    name: "Supervisor linkblue",
    property: "supervisorLinkBlueId",
    sortable: true,
    nowrap: true,
  },
  {
    name: "Proxy",
    property: "proxyName",
    sortable: true,
    nowrap: true,
  },
  {
    name: "Proxy linkblue",
    property: "proxyLinkBlueId",
    sortable: true,
    nowrap: true,
  },
  {
    align: "center",
    name: "Supervisor Signature",
    // property: "supervisorSignedFormatted",
    property: "supervisorSignatureColumn",
    sortable: true,
    sortableProperty: "supervisorSigned",
    nowrap: true,
  },
  // {
  //   align: "center",
  //   name: "Supervisor Signature Date",
  //   property: "supervisorSignatureDateFormatted",
  //   sortable: true,
  //   sortableProperty: "supervisorSignatureDate",
  //   nowrap: true,
  // },
  {
    align: "center",
    name: "Individual Authorized Signature",
    // property: "authorizedIndividualSignedFormatted",
    property: "individualAuthorizedSignatureColumn",
    sortable: true,
    sortableProperty: "authorizedIndividualSigned",
    nowrap: true,
  },
  // {
  //   align: "center",
  //   name: "Individual Authorized Signature Date",
  //   property: "authorizedIndividualSignatureDateFormatted",
  //   sortable: true,
  //   sortableProperty: "authorizedIndividualSignatureDate",
  //   nowrap: true,
  // },
  {
    align: "center",
    name: "Employee Signature",
    // property: "employeeSignedFormatted",
    property: "employeeSignatureColumn",
    sortable: true,
    sortableProperty: "employeeSigned",
    nowrap: true,
  },
  // {
  //   align: "center",
  //   name: "Employee Signature Date",
  //   property: "employeeSignatureDateFormatted",
  //   sortable: true,
  //   sortableProperty: "employeeSignatureDate",
  //   nowrap: true,
  // },
  {
    name: "Comments",
    property: "commentsToShow",
    align: "center",
  },
];
