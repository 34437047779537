import { DateTime } from "luxon";
import { PrimaryReasons, SecondaryReasons } from "../components/Reasons";

var Buffer = require("buffer/").Buffer;

export const encodeBase64 = (str) => Buffer.from(str).toString("base64");

export const formatFetchUrl = (
  endpoint,
  linkblue = null,
  itemsPerPage,
  currentPage,
  sortedBy,
  sortDescending,
  search,
  readyForReviewFilter,
  employeeSignedFilter,
  employeeSignatureNotDesignatedFilter,
  supervisorSignedFilter,
  supervisorSignatureNotDesignatedFilter,
  authorizedIndividualSignedFilter,
  authorizedIndividualSignatureNotDesignatedFilter,
  hrCompleteFilter,
  canceledFilter,
  exportAll = false
) => {
  const url = new URL(window.REACT_APP_API_URL + "/" + endpoint);
  url.searchParams.set("pageSize", itemsPerPage);
  url.searchParams.set("currentPage", currentPage);

  if (linkblue !== null) {
    url.searchParams.set("requestorLinkBlueId", linkblue);
  }

  // Sorting
  url.searchParams.set("orderBy", sortedBy);
  if (sortDescending) {
    url.searchParams.set("orderByDirection", "desc");
  } else {
    url.searchParams.delete("orderByDirection");
  }

  // Searching
  if (search) {
    url.searchParams.set("search", search);
  }

  // Filtering
  if (readyForReviewFilter) {
    url.searchParams.set("readyForReview", readyForReviewFilter);
  }

  if (employeeSignedFilter) {
    url.searchParams.set("employeeSigned", employeeSignedFilter);
    url.searchParams.set("skipEmployeeSignature", false);
  }

  if (employeeSignatureNotDesignatedFilter) {
    url.searchParams.set(
      "employeeSignatureNotDesignated",
      employeeSignatureNotDesignatedFilter
    );
  }

  if (supervisorSignedFilter) {
    url.searchParams.set("supervisorSigned", supervisorSignedFilter);
  }

  if (supervisorSignatureNotDesignatedFilter) {
    url.searchParams.set(
      "supervisorSignatureNotDesignated",
      supervisorSignatureNotDesignatedFilter
    );
  }

  if (authorizedIndividualSignedFilter) {
    url.searchParams.set(
      "authorizedIndividualSigned",
      authorizedIndividualSignedFilter
    );
  }

  if (authorizedIndividualSignatureNotDesignatedFilter) {
    url.searchParams.set(
      "authorizedIndividualSignatureNotDesignated",
      authorizedIndividualSignatureNotDesignatedFilter
    );
  }

  if (hrCompleteFilter) {
    url.searchParams.set("hrComplete", hrCompleteFilter);
  }

  if (canceledFilter) {
    url.searchParams.set("canceled", canceledFilter);
  }

  if (exportAll) {
    url.searchParams.set("export", exportAll);
  }

  url.toString();
  return url;
};

export const formatFetchData = (data, user) => {
  const formattedData = data;

  formattedData.forEach((element) => {
    // Employee signed column: No, Not designated, or date
    element.employeeSignatureColumn = element.employeeSignatureNotDesignated
      ? "Not designated"
      : element.employeeSigned && element.employeeSignatureDate !== null
      ? DateTime.fromISO(element.employeeSignatureDate).toFormat("MM/dd/yyyy")
      : "No";

    // Supervisor signed column: No, Not designated, or date
    element.supervisorSignatureColumn = element.supervisorSignatureNotDesignated
      ? "Not designated"
      : element.supervisorSigned && element.supervisorSignatureDate !== null
      ? DateTime.fromISO(element.supervisorSignatureDate).toFormat("MM/dd/yyyy")
      : "No";

    // IA signed column: No, Not designated, or date
    element.individualAuthorizedSignatureColumn =
      element.authorizedIndividualSignatureNotDesignated
        ? "Not designated"
        : element.authorizedIndividualSigned &&
          element.authorizedIndividualSignatureDate !== null
        ? DateTime.fromISO(element.authorizedIndividualSignatureDate).toFormat(
            "MM/dd/yyyy"
          )
        : "No";

    if (element.separationDate) {
      element.separationDateFormatted = DateTime.fromISO(
        element.separationDate
      ).toFormat("MM/dd/yyyy");
    } else {
      element.separationDateFormatted = "";
    }

    if (
      (!(
        element.authorizedIndividualSignatureNotDesignated ||
        element.authorizedIndividualSigned
      ) ||
        !(
          element.supervisorSigned || element.supervisorSignatureNotDesignated
        ) ||
        !(
          element.employeeSigned ||
          element.employeeSignatureNotDesignated ||
          element.skipEmployeeSignature
        )) &&
      !element.canceled
    ) {
      element.missingSignatures = true;
      element.displayAsMissingSignaturesInTable = true;
    } else {
      element.missingSignatures = false;
      element.displayAsMissingSignaturesInTable = false;
    }

    // Create formatted employee name property
    element.employeeNameFormatted = `${element.employeeLastName}, ${element.employeeFirstName}`;

    // Create formatted supervisor name property
    if (element.supervisorName) {
      var supervisorWithoutNumber = element.supervisorName.split(" - ")[0];
      element.supervisorNameFormatted = `${supervisorWithoutNumber.trim()}`;
    } else {
      element.supervisorNameFormatted = "";
    }

    // Create formatted supervisorSigned property
    if (element.supervisorSigned) {
      element.supervisorSignedFormatted = "Yes";
    } else {
      element.supervisorSignedFormatted =
        element.supervisorSignatureNotDesignated ? "Not designated" : "No";
    }

    // Create formatted supervisorSignatureDateFormatted property
    if (element.supervisorSigned) {
      element.supervisorSignatureDateFormatted = DateTime.fromISO(
        element.supervisorSignatureDate
      ).toFormat("MM/dd/yyyy");
    } else {
      element.supervisorSignatureDateFormatted = "";
    }

    // Create formatted auth ind name property
    if (element.authorizedIndividualName) {
      var authorizedIndividualWithoutNumber =
        element.authorizedIndividualName.split(" - ")[0];
      var authorizedIndividualNameSplit =
        authorizedIndividualWithoutNumber.split(", ");

      element.authorizedIndividualNameFormatted =
        authorizedIndividualNameSplit.length > 1
          ? `${authorizedIndividualNameSplit[1].trim()} ${authorizedIndividualNameSplit[0].trim()}`
          : authorizedIndividualNameSplit[0];
    } else {
      element.authorizedIndividualNameFormatted = "";
    }

    // Create formatted authorizedIndividualSigned property
    if (element.authorizedIndividualSigned) {
      element.authorizedIndividualSignedFormatted = "Yes";
    } else {
      element.authorizedIndividualSignedFormatted =
        element.authorizedIndividualSignatureNotDesignated
          ? "Not designated"
          : "No";
    }

    // Create formatted authorizedIndividualSignatureDateFormatted property
    if (element.authorizedIndividualSigned) {
      element.authorizedIndividualSignatureDateFormatted = DateTime.fromISO(
        element.authorizedIndividualSignatureDate
      ).toFormat("MM/dd/yyyy");
    } else {
      element.authorizedIndividualSignatureDateFormatted = "";
    }

    // Create formatted employeeSigned property
    if (element.employeeSigned) {
      element.employeeSignedFormatted = "Yes";
    } else {
      element.employeeSignedFormatted = element.employeeSignatureNotDesignated
        ? "Not designated"
        : element.skipEmployeeSignature
        ? "Skipped"
        : "No";
    }

    // Create formatted employeeSignatureDateFormatted property
    if (element.employeeSigned) {
      element.employeeSignatureDateFormatted = DateTime.fromISO(
        element.employeeSignatureDate
      ).toFormat("MM/dd/yyyy");
    } else {
      element.employeeSignatureDateFormatted = "";
    }

    // Create a readyForReview property
    if (
      (element.isTodayOnOrAfterSeparationDate || !element.missingSignatures) &&
      !element.hrComplete &&
      !element.canceled
    ) {
      element.readyForReview = true;

      if (element.missingSignatures) {
        element.displayAsMissingSignaturesInTable = false;
      }
    } else {
      element.readyForReview = false;
    }

    // Create formatted firstDateWorkedFormatted property
    if (element.firstDateWorked) {
      element.firstDateWorkedFormatted = DateTime.fromISO(
        element.firstDateWorked
      ).toFormat("MM/dd/yyyy");
    }

    // Create formatted lastDateWorkedFormatted property
    if (element.lastDateWorked) {
      element.lastDateWorkedFormatted = DateTime.fromISO(
        element.lastDateWorked
      ).toFormat("MM/dd/yyyy");
    }

    // Create formatted dateNoticeGivenFormatted property
    if (element.dateNoticeGiven) {
      element.dateNoticeGivenFormatted = DateTime.fromISO(
        element.dateNoticeGiven
      ).toFormat("MM/dd/yyyy");
    }

    // Create formatted separationDateFormatted property
    if (element.separationDate) {
      element.separationDateFormatted = DateTime.fromISO(
        element.separationDate
      ).toFormat("MM/dd/yyyy");
    }

    // Create a separationReasonsFormatted property
    if (element.separationReasons.length > 0) {
      element.separationReasonsFormatted = Array.prototype.map
        .call(element.separationReasons, (item) => {
          return item.separationReasonText;
        })
        .join(", ");

      // Get primary reason value
      const primaryReasonArray = PrimaryReasons.filter((primaryReason) => {
        return element.separationReasons.some((propSeparationReason) => {
          return (
            primaryReason.label.toLowerCase() ===
            propSeparationReason.separationReasonText.toLowerCase()
          );
        });
      }).map((reason) => reason.label);
      element.primaryReasonValue = primaryReasonArray[0];

      // get the records secondary reason labels into an array
      const secondaryReasonArray = SecondaryReasons.filter(
        (secondaryReason) => {
          return element.separationReasons.some((propSeparationReason) => {
            return (
              secondaryReason.label.toLowerCase() ===
              propSeparationReason.separationReasonText.toLowerCase()
            );
          });
        }
      ).map((secondaryReason) => secondaryReason.label);
      element.secondaryReasonValue = secondaryReasonArray[0];
    }

    // Canceled
    if (element.canceled) {
      element.canceledFormatted = "true";
    } else {
      element.canceledFormatted = "false";
    }

    if (
      !element.supervisorSigned &&
      !element.supervisorSignatureNotDesignated
    ) {
      // Set supervisor signing phase
      element.signingPhase = "supervisor";
      element.supervisorCanSign = true;
      element.authorizedIndividualCanSign = false;
      element.employeeCanSign = false;
    }

    // Set authorized individual signing phase
    if (
      (element.supervisorSigned || element.supervisorSignatureNotDesignated) &&
      !element.authorizedIndividualSigned &&
      !element.authorizedIndividualSignatureNotDesignated
    ) {
      element.signingPhase = "authorizedIndividual";
      element.supervisorCanSign = false;
      element.authorizedIndividualCanSign = true;
      element.employeeCanSign = false;
      // Pass along if auth individual can edit when sup not designated
      element.authorizedIndividualCanEdit =
        element.authorizedIndividualLinkBlueId === user.linkblue &&
        element.supervisorSignatureNotDesignated;
    }

    // Set employee signing phase
    if (
      (element.supervisorSigned || element.supervisorSignatureNotDesignated) &&
      (element.authorizedIndividualSigned ||
        element.authorizedIndividualSignatureNotDesignated) &&
      !element.employeeSigned &&
      !element.employeeSignatureNotDesignated
    ) {
      element.signingPhase = "employee";
      element.supervisorCanSign = false;
      element.authorizedIndividualCanSign = false;
      element.employeeCanSign = true;
    }

    // Set HR approval phase
    if (
      (element.supervisorSigned || element.supervisorSignatureNotDesignated) &&
      (element.authorizedIndividualSigned ||
        element.authorizedIndividualSignatureNotDesignated) &&
      (element.employeeSigned ||
        element.employeeSignatureNotDesignated ||
        element.skipEmployeeSignature)
    ) {
      element.signingPhase = "hr";
      element.supervisorCanSign = false;
      element.authorizedIndividualCanSign = false;
      element.employeeCanSign = false;
    }

    console.log("formatFetchData isAdministrator", user.isAdministrator);

    if (
      (element.canceledComments !== null ||
        element.skipEmployeeComment !== null ||
        element.completedComment !== null) &&
      (user.isAdministrator || user.isHrManager)
    ) {
      let commentsArray = [];
      let commentsMessage = "";

      if (element.canceledComments !== null) {
        commentsArray.push(
          `<strong>Reason for cancellation:</strong> ${element.canceledComments}`
        );
      }
      if (element.skipEmployeeComment) {
        commentsArray.push(
          `<strong>Reason for skipping employee signature:</strong> ${element.skipEmployeeComment}`
        );
      }

      if (element.completedComment) {
        commentsArray.push(
          `<strong>Completed comments:</strong> ${element.completedComment}`
        );
      }

      commentsMessage = commentsArray.join("<br /><br />");
      element.commentsToShow = (
        <div
          data-toggle="tooltip"
          data-placement="left"
          data-html="true"
          title={commentsMessage}
          data-container="body"
        >
          <i class="bi bi-chat-dots"></i>
        </div>
      );
    } else {
      element.commentsToShow = "";
    }
  });

  console.log("formatFetchData formattedData", formattedData);

  return formattedData;
};

async function modifyRecord(user, recordId, scenario) {
  const today = DateTime.now().toFormat("yyyy-MM-dd TT");

  const patchBody = [
    {
      op: "replace",
      path: "/supervisorSignatureNotDesignated",
      value: scenario.supervisorSignatureNotDesignated,
    },
    {
      op: "replace",
      path: "/authorizedIndividualSignatureNotDesignated",
      value: scenario.authorizedIndividualSignatureNotDesignated,
    },
    {
      op: "replace",
      path: "/employeeSignatureNotDesignated",
      value: scenario.employeeSignatureNotDesignated,
    },
    {
      op: "replace",
      path: "/supervisorSigned",
      value: scenario.supervisorSigned,
    },
    {
      op: "replace",
      path: "/supervisorSignatureDate",
      value: scenario.supervisorSigned ? today : null,
    },
    {
      op: "replace",
      path: "/authorizedIndividualSigned",
      value: scenario.authorizedIndividualSigned,
    },
    {
      op: "replace",
      path: "/authorizedIndividualSignatureDate",
      value: scenario.authorizedIndividualSigned ? today : null,
    },
    {
      op: "replace",
      path: "/employeeSigned",
      value: scenario.employeeSigned,
    },
    {
      op: "replace",
      path: "/employeeSignatureDate",
      value: scenario.employeeSigned ? today : null,
    },
    {
      op: "replace",
      path: "/hrComplete",
      value: scenario.hrComplete,
    },
    {
      op: "replace",
      path: "/hrCompleteBy",
      value: user.linkblue,
    },
    {
      op: "replace",
      path: "/employeeLinkBlueId",
      value: scenario.employeeLinkblue,
    },
    {
      op: "replace",
      path: "/supervisorLinkBlueId",
      value: scenario.supervisorLinkblue,
    },
    {
      op: "replace",
      path: "/canceled",
      value: scenario.canceled,
    },
    {
      op: "replace",
      path: "/skipEmployeeSignature",
      value: scenario.skipEmployeeSignature,
    },
    {
      op: "replace",
      path: "/skipEmployeeComment",
      value: scenario.skipEmployeeComment,
    },
  ];

  if (scenario.supervisorName) {
    patchBody.push({
      op: "replace",
      path: "/supervisorName",
      value: scenario.supervisorName,
    });
  }

  if (scenario.authorizedIndividualName) {
    patchBody.push({
      op: "replace",
      path: "/authorizedIndividualName",
      value: scenario.authorizedIndividualName,
    });
  }

  const url = new URL(window.REACT_APP_API_URL + "/records/" + recordId);
  url.toString();

  const updateRecord = async () => {
    try {
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.apiToken.accessToken}`,
        },
        body: JSON.stringify(patchBody),
      });
      const json = await response.json();
      if (json) {
        return true;
      }
    } catch (error) {
      if (window.REACT_APP_DEBUG) {
        console.log("modifyRecord error: " + error);
        console.dir(error);
      }
    }
  };
  updateRecord();
}

export { modifyRecord };
